import React, { useLayoutEffect } from "react";
import { makeDocumentTitle } from "../utils/utils";
import Footer from "../components/Footer";

export const TermsPage = () => {
    const { REACT_APP_HOST } = process.env;
    const url = "https://" + REACT_APP_HOST;
    const email = "help@" + REACT_APP_HOST;
    const mailto = "mailto:" + email;

    useLayoutEffect(() => {
        document.title = makeDocumentTitle("Пользовательское соглашение");
    }, []);

    return (
        <div className="container">
            <div className="space">
            <h1 className="mb-3">Пользовательское соглашение</h1>
            <p>Пожалуйста, внимательно ознакомьтесь с настоящим пользовательским соглашением перед использованием нашего сайта и предоставлением каких-либо услуг.</p>
            <p><strong>Определения</strong>. В настоящем соглашении используются следующие определения:</p>
            <ul>
                <li>"Сайт" означает {REACT_APP_HOST}, доступный по адресу {url}.</li>
                <li>"Мы", "наш", "компания" означают {REACT_APP_HOST}, владельца и администратора сайта.</li>
                <li>"Вы", "пользователь" означают любого посетителя, который использует наш сайт и/или совершает покупки через наш сайт.</li>
                <li>"Услуги" означают любые продукты, услуги или информацию, предоставляемые через наш сайт.</li>
                <li>"Криптовалюта" означает любую цифровую валюту, такую как Bitcoin, Ethereum, TON и другие.</li>
            </ul>
            <p><strong>Принятие условий</strong>. Используя наш сайт и/или предоставляя нам какие-либо персональные данные, вы соглашаетесь с условиями настоящего соглашения.</p>
            <p><strong>Платежи в криптовалюте</strong>. Мы принимаем платежи в криптовалюте за наши товары и услуги. При совершении платежей в криптовалюте применяются соответствующие курсы обмена на момент совершения покупки.</p>
            <p><strong>Отказ от ответственности</strong>. Мы не несем ответственности за любые потери, возникшие в результате использования криптовалюты, включая, но не ограничиваясь, потерей доступа к кошельку, ошибками в совершении платежей или изменениями в стоимости криптовалюты.</p>
            <p><strong>Защита персональных данных</strong>. Мы ценим вашу конфиденциальность и обязуемся защищать ваши персональные данные согласно нашей политике конфиденциальности.</p>
            <p><strong>Изменения и дополнения</strong>. Мы оставляем за собой право вносить изменения и дополнения в настоящее пользовательское соглашение по своему усмотрению. Любые изменения будут размещены на нашем сайте.</p>
            <p><strong>Контактная информация</strong>. Если у вас возникли вопросы или предложения относительно настоящего пользовательского соглашения, пожалуйста, свяжитесь с нами по следующим контактным данным: <a href={mailto}>{email}</a>.</p>
            </div>
            <Footer showHowTo={false} />
        </div>
    );
}
