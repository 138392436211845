interface ErrorProps {
    msg: string | number | null;
}

export const Error = ({msg}: ErrorProps) => {
 return (
    <div className="container blank-error-message pt-4" style={{"maxWidth": "599px"}}>
        <h1 className="mb-3">Вы нашли ошибку {msg}</h1>
        <p>
            Если вы попали на эту страницу, значит произошло что-то непредсказуемое.
            <div>Вероятно, наши специалисты уже знают о данной проблеме, но при необходимости вы можете связаться с нами по почте <a href="mailto:help@0x7db.ru">help@0x7db.ru</a>.</div>
        </p>
        <p className="fst-italic">Благодарим вас за использование нашего сервиса.</p>
    </div>
 ); 
}