import { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Popup } from "reactjs-popup";
import { Popover, ArrowContainer } from "react-tiny-popover";

import { getSession, pickSession, confirmSession } from "../api";
import { makeDocumentTitle, timeCounter, timeTimestamp, copyToClipboard } from "../utils/utils";
import { NotifyForm } from "../components/NotifyForm";
import { ISession, ISessionPick, IShop, ICoin, SessionDeclined, SessionExpired, SessionCreated, SessionChecking, SessionPaid, Sessionl10n } from "../types";

import "reactjs-popup/dist/index.css";
import Footer from "../components/Footer";
import { Error } from "../components/Error";

interface SessionProps {
    session: ISession;
    checkSession: any;
    sessionPick: ISessionPick;
}

const Session = ({session, checkSession, sessionPick}: SessionProps) => {
    const [openAddrTooltip, setOpenAddrTooltip] = useState<boolean>(false);
    const [openAmountTooltip, setOpenAmountTooltip] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        confirmSession(sessionPick.confirmation)
            .then((confirm) => {
                checkSession()
            })
            .catch((error) => {
                console.log("error", error);
            })
    };

    return (
        <div>
            <div className="mb-3">
                Пожалуйста, сделайте прямой перевод на сумму:
                <div className="mt-1">
                    <div className="mark-text">
                        <div>{sessionPick.hAmount} {sessionPick.coin}</div>
                        <Popover 
                            isOpen={openAmountTooltip} 
                            positions={['top', 'bottom', 'left', 'right']}
                            onClickOutside={() => setOpenAmountTooltip(false)}  
                            content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                                position={position}
                                childRect={childRect}
                                popoverRect={popoverRect}
                                arrowColor={'black'}
                                arrowSize={3}
                                arrowStyle={{ opacity: 1 }}
                                className='popover-arrow-container'
                                arrowClassName='popover-arrow'
                            >
                            <div className="popover-content" onClick={() => setOpenAmountTooltip(!openAmountTooltip)}>
                                сумма скопирована
                            </div>
                            </ArrowContainer>
                        )}
                        >
                            <span className="copy" onClick={() => {copyToClipboard(sessionPick.hAmount); setOpenAmountTooltip(true)}}><i className="bi bi-copy"></i></span>
                        </Popover>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div>В адрес кошелька {sessionPick.coin}. Скопируйте адрес ниже:</div>
                <div className="mt-1">
                    <div className="mark-text text-nowrap">
                        <div className="address">{sessionPick.address}</div>
                        <Popover 
                            isOpen={openAddrTooltip} 
                            positions={['top', 'bottom', 'left', 'right']}
                            onClickOutside={() => setOpenAddrTooltip(false)}  
                            content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                                position={position}
                                childRect={childRect}
                                popoverRect={popoverRect}
                                arrowColor={'black'}
                                arrowSize={3}
                                arrowStyle={{ opacity: 1 }}
                                className='popover-arrow-container'
                                arrowClassName='popover-arrow'
                            >
                            <div className="popover-content" onClick={() => setOpenAddrTooltip(!openAddrTooltip)}>
                                адрес скопирован
                            </div>
                            </ArrowContainer>
                        )}
                        >
                            <span className="copy" onClick={() => {copyToClipboard(sessionPick.address); setOpenAddrTooltip(true)}}><i className="bi bi-copy"></i></span>
                        </Popover>
                    </div>
                </div>
            </div>
            <div>
                <div>Или отсканируйте QR-код:</div>
                <div className="mx-auto"><img src={sessionPick.qr} style={{maxWidth: "250px", margin: "auto"}} alt="" /></div>
            </div>
            {session.status == SessionCreated ? (
                <div className="mt-2">
                    <a href="#" onClick={handleClick} className="d-block btn btn-primary">я опллатил</a>
                </div>
            ) : <></>} 
        </div>
    );
}

interface ErrorSessionProps {
    message: string;
    redirectUrl: string | undefined;
}

const ErrorSession = ({ message, redirectUrl }:ErrorSessionProps ) => {
    return (
        <div className="result-message">
            <div className="">{message}</div>
            <div className="text-center ico text-danger"><i className="bi bi-x-octagon"></i></div>
            <div>Если вы считаете это сообщение ошибочным, пожалуйста, обратитесь в службу поддержки <a href="mailto:help@0x7db.ru">help@0x7db.ru</a>.</div>
            <div className="mt-3"><a href={redirectUrl? redirectUrl : "/"} className="btn d-block btn-primary">вернуться обратно</a></div>
        </div>
    );
}

interface PaidSessionProps {
    sessionPick: ISessionPick;
    redirectUrl: string | undefined;
}

const PaidSession = ({ sessionPick, redirectUrl }:PaidSessionProps) => {
    return (
        <div className="result-message">
            <div className="">Ваш платеж успешно обработан!</div>
            <div className="text-center ico text-success">
                <i className="bi bi-check2-circle"></i>
            </div>
            <div>Если не произошла автоматическая переадресация, нажмите на кнопку ниже:</div>
            <div className="mt-3"><a href={redirectUrl? redirectUrl : "/"} className="btn d-block btn-primary">вернуться обратно</a></div>
        </div>
    );
}

interface CoinsProps {
    session: ISession;
    setSessionPick: any;
}

const Coins = ({session, setSessionPick}:CoinsProps) => {
    const handleClick = (coin: string) => (event: React.MouseEvent<HTMLDivElement>) => {
        pickSession(session.sessionId, coin)
            .then((resp) => {
                setSessionPick(resp.data.response);
            })
            .catch((error) => {
                console.log("error", error);
            });
      };

    return (
        <div className="row coins">
            {session.coins.map((x, i) => 
                <div className="col-6 mb-2" key={x.symbol}>
                    <div className="coin text-start d-block  btn btn-secondary" onClick={handleClick(x.symbol)}>
                        <div className="symbol">{x.symbol}</div>
                        <div className="amount">{x.hAmount}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

const SessionPage = () => {
    var defaultCounter = 3600 - 1;
    var defaultSession: ISession = {sessionId: "", status: "", backText: "", backUrl: "", failUrl: "", successUrl: "", counter: defaultCounter, createdAt: 0, coins: [], amount: 0, currency: "", qr: "", isTest: false};
    var defaultSessionPick: ISessionPick = {address: "", hAmount: "", coin: "", qr: "", confirmation: ""};
    var intervalSession: any = null;

    const { id } = useParams();
    

    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [shop, setShop] = useState<IShop>({title: "", id: ""});
    const [session, setSession ] = useState<ISession>(defaultSession);
    const [sessionPick, setSessionPick ] = useState<ISessionPick>(defaultSessionPick);
    const [counter, setCounter] = useState<number>(defaultCounter); 
    const [error, setError] = useState<number>(0)
    
    const checkSession = () => {
        getSession(new String(id).toString())
            .then((respSession) => {
                setSession(respSession.data.response);
                setShop(respSession.data.response.shop);
                if (respSession.data.response.pick.address !== "") {
                    setSessionPick(respSession.data.response.pick);
                }
                if (respSession.data.response.counter < counter) {
                    setCounter(respSession.data.response.counter);
                }
                if (respSession.data.response.status !== SessionCreated && respSession.data.response.status !== SessionChecking) {
                    clearInterval(intervalSession);
                }
            })
            .catch((error) => {
                setError(error.response.status);
                console.log("error", error);
            })
    }

    useLayoutEffect(() => {
        document.title = makeDocumentTitle(`Платеж #${id}`);
    }, []);

    useEffect(() => {
        checkSession();
        intervalSession = setInterval(() => {
            checkSession();
        }, 5000);
    }, []);

    useEffect(() => {
        const count = setInterval(() => {
            setCounter((counter) => counter - 1);
          }, 1000);
          return () => clearInterval(count);
    }, []);

    return (
        <>
        {session.isTest ? <>
        <div className="progress" style={{"borderRadius": "0", "height": "2rem"}}>
            <div className="progress-bar progress-bar-striped bg-danger" style={{"width": "100%"}}>
                ТЕСТОВЫЙ ПЛАТЕЖ
            </div>
        </div>
        </>: <></>}
        {session.status !== "" ? <>
        <div className="cosmo">
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8 mb-2 mx-auto header">
                    <div className="row">
                        <div className="col left">
                            {sessionPick.address && session.status === SessionCreated ? (
                                <a href="#" onClick={(e) => {e.preventDefault();setSessionPick(defaultSessionPick)}} className="text-muted">
                                    <i className="bi bi-arrow-left-short"></i>
                                    выбор валюты
                                </a>
                            ) : (
                                <a href={session.backUrl ? session.backUrl : "/"} className="text-muted">
                                    <i className="bi bi-arrow-left-short"></i>
                                        {session.backText ? session.backText : (
                                            <>{session.status == SessionCreated || session.status == SessionChecking ? "отмена оплаты" : "назад"}</>
                                        )}
                                </a>
                            )}
                        </div>
                        <div className="col right">
                            <a onClick={() => {setOpenDetail(true)}} href="#">Детали платежа</a>
                            <Popup open={openDetail} closeOnDocumentClick onClose={() => setOpenDetail(false)} className="cosmo-popup" modal>
                                <span className="close" onClick={() => {setOpenDetail(false)}}>&times;</span>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="mb-3">
                                            <div className="h5">Идентификатор платежа: </div>
                                            <span>{id}</span>
                                        </div>
                                        <div className="mb-3">
                                            <div className="h5">Дата создания: </div>
                                            <span>{timeTimestamp(session.createdAt)}</span>
                                        </div>
                                        {shop.title !== "" ? <div className="mb-3">
                                            <div className="h5">Магазин: </div>
                                            <span>{shop.title}</span>
                                        </div>: <></> }
                                        <div className="mb-3">
                                            <div className="h5">Статус платежа: </div>
                                            <div>{Sessionl10n[session.status]}</div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="h5">Сумма: </div>
                                            <span>
                                                {session.amount / 100} {session.currency} 
                                                {sessionPick.hAmount ? <span> ≈ {sessionPick.hAmount} {sessionPick.coin}</span> : <></>}
                                            </span>
                                        </div>
                                        {sessionPick.address != "" ? (
                                        <div className="mb-3">
                                            <div className="h5">Адрес пополнения: </div>
                                            <span className="text-nowrap"><span className="address">{sessionPick.address}</span></span>
                                        </div>
                                        ) : <></>}                                        
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <img src={session.qr} alt="" className="qr" />
                                    </div>
                                </div>
                            </Popup>
                        </div>
                   </div>
                </div>
            </div>
            {session.status === SessionCreated || session.status == SessionChecking ? (
            <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8 mb-4 mx-auto content">
                    <NotifyForm id={new String(id).toString()} />
                </div>
            </div>
            ): <></>}   
            <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8 mx-auto content">
                    <div className="h5 mb-3 id"><span>Платеж #{id}</span></div> 
                    {session.status === SessionDeclined ? (
                        <ErrorSession redirectUrl={session.failUrl} message="Ваш платеж был отклонен!" />
                    ) : session.status === SessionExpired ? (
                        <ErrorSession redirectUrl={session.failUrl} message="Истекло время ожидания оплаты платежа!" />
                    ) : session.status === SessionPaid ? (
                        <PaidSession redirectUrl={session.successUrl} sessionPick={sessionPick} />
                    ) : (
                    <>
                        {!sessionPick.address ? (
                            <Coins session={session} setSessionPick={setSessionPick} />
                        ) : (
                            <Session session={session} checkSession={checkSession} sessionPick={sessionPick} />
                        )}
                        <div className="mt-4">
                            <div className="text-center mb-2">
                                <div className="spinner">
                                    <div className="spinner-circle spinner-border"></div>
                                </div>
                            </div>
                            <p className="text-center">
                                {session.status == SessionCreated ? <span>Ожидаем вашу оплату </span> : <span>Ожидаем подтверждения оплаты </span> }
                                    ({timeCounter(counter)})
                            </p>
                        </div>
                    </>)}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-7 col-12 mt-4 mx-auto footer">
                    <div className="text-center text-muted">
                        <small className="">Используя наш сервис, вы соглашаетесь с <a href="/terms">Пользовательским соглашением</a>, <a href="/privacy">Политикой конфиденциальности</a> и даёте cогласие на обработку персональных данных.</small>
                    </div>
                    <Footer showHowTo={true} />
                </div>
            </div>
        </div>
        </div>
        </> : (<>{error > 0 ? <Error msg={error} /> : <></>} </>)}
        </>
    );
}

export default SessionPage;