interface FooterProps {
    showHowTo: boolean;
}

const Footer = ({showHowTo}: FooterProps) => {
    return (
        <div className="pb-3">
            <hr style={{width: "100%"}} />
            <a href="/">0x7db.ru</a> <i className="bi bi-dot"></i> <a href="mailto:help@0x7db.ru">help@0x7db.ru</a> {showHowTo ? <><i className="bi bi-dot"></i> <a href="/howto">Как оплатить?</a></> : <></>}
        </div>
    );
}

export default Footer;