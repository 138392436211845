export const SessionDeclined = "DECLINED";
export const SessionExpired  = "EXPIRED";
export const SessionCreated  = "CREATED";
export const SessionChecking = "CHECKING";
export const SessionPaid     = "PAID";

export const Sessionl10n: any = {
    DECLINED: "отклонен",
    EXPIRED: "истек",
    CREATED: "новый",
    CHECKING: "проверка",
    PAID: "исполнен",
};

export interface ISession {
    sessionId: string;
    status: string;
    counter: number;
    amount: number;
    currency: string;
    createdAt: number;
    coins: ICoin[];
    backText: string;
    backUrl: string;
    failUrl: string;
    successUrl?: string;
    qr: string;
    isTest: boolean;
}

export interface ISessionPick {
    address: string;
    hAmount: string;
    coin: string;
    qr: string;
    confirmation: string;
}

export interface IShop {
    id: string;
    title: string;
}

export interface ICoin {
    amount: string;
    hAmount: string;
    symbol: string;
    token: string;
    isActive: boolean;
}