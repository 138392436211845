const Front = () => {
    return (
        <div className="infinity">
            <div className="container">
                <table>
                    <tr>
                        <td className="va">
                            <h1><span>0x7db.ru</span></h1>
                            <div className="slogan"><div>Прием </div><div>платежей</div> <div className="text-nowrap">в криптовалюте</div></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default Front;