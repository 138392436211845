import React, { useLayoutEffect } from "react";
import { makeDocumentTitle } from "../utils/utils";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export const HowToPage = () => {
    const { REACT_APP_HOST } = process.env;
    const email = "help@" + REACT_APP_HOST;
    const mailto = "mailto:" + email;

    useLayoutEffect(() => {
        document.title = makeDocumentTitle("Как оплатить?");
    }, []);

    return (
        <div className="container">
            <div className="space cosmo">
            <h1 className="mb-3">Оплата</h1>
            <p>Оплата через криптовалюту мало чем отличается от оплаты через банковскую карту. Вместо банковского приложения у вас должен быть электронный кошелек с поддержкой выбранной криптовалюты. На балансе должна быть достаточная сумма для оплаты платежа.</p>
            <p>Весь процесс оплаты автоматизирован, и все, что вам необходимо сделать, это перевести указанную сумму на адрес, который вам выдала система. Далее остается только ждать подтверждения поступления оплаты. Если все прошло успешно, мы оповестим магазин (или сервис) о поступлении средств. 
                <div>На оплату дается 1 час. Если вы произвели оплату, но не получили подтверждения, напишите нам на почту <a href="mailto:help@0x7db.ru">help@0x7db.ru.</a></div>
            </p>
            <div className="row mt-4">
                <div className="col-xl-8 col-lg-6">
                    <h5>Как оплатить?</h5>
                    <p>Далее мы рассмотрим процесс оплаты платежа на примере криптовалюты TON и приложения Tonkeeper:</p>
                    <ol className="">
                        <li>
                        Получение реквизитов для оплаты. Находясь на странице платежа, скопируйте сначала адрес, куда необходимо перевести средства. Для этого нажмите на иконку "копировать" или отсканируйте QR-код.
                        </li>
                        <li>
                        Откройте приложение кошелька и нажмите кнопку "отправить". Вставьте ранее скопированный адрес. Большинство приложений кошельков предоставляют возможность сканирования QR-кода. Если такая функция имеется в вашем случае, используйте ее.
                        </li>
                        <li>Вернитесь на страницу с реквизитами платежа, скопируйте сумму и вставьте ее в поле ввода суммы.</li>
                        <li>Нажмите кнопку продолжить.</li>
                        <li>Проверьте сумму и получателя оплаты. Если сумма неверная, вернитесь на шаг назад и введите сумму вручную. Если все верно, нажимайте "подтвердить и отправить".</li>
                        <li>В разделе "история" должен появиться ваш перевод.</li>
                        <li>Как только мы проверим вашу оплату, на странице платежа появится соответствующее сообщение. Для возврата обратно в магазин, если это не произошло автоматически, нажмите кнопку "вернуться обратно".</li>
                    </ol>
                    <p>Вы можете указать ваш email для получения уведомления об оплате на вашу почту.</p>
                    <p>Если вы передумали платить, просто закройте вкладку платежа или нажмите на кнопку со стрелкой назад вверху страницы.</p>
                    <h5>Где купить криптовалюту?</h5>
                    <p>Криптовалюту можно приобрести на специализированных биржах в обмен на фиатные деньги. Список сайтов, доступных в вашем регионе, вы можете найти через поисковые системы.</p>
                    <p>TON и некоторые другие криптовалюты можно купить через Telegram, используя встроенное приложение <a href="https://t.me/wallet" target="__blank">Wallet</a>.</p>
                    <p>Напоминаем, что мы не несем ответственности за переводы, совершенные вне рамок оплаты платежей, которые осуществляются через наш сервис.</p>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <Carousel autoPlay={true} className="guide-slider" showStatus={false} showThumbs={false}>
                        <div>
                            <img src="images/howtoton/1.png" />
                        </div>
                        <div>
                            <img src="images/howtoton/2.png" />
                        </div>
                        <div>
                            <img src="images/howtoton/3.png" />
                        </div>
                        <div>
                            <img src="images/howtoton/4.png" />
                        </div>
                        <div>
                            <img src="images/howtoton/5.png" />
                        </div>
                        <div>
                            <img src="images/howtoton/6.png" />
                        </div>
                    </Carousel>
                </div>
            </div>
            </div>
            <Footer showHowTo={false} />
        </div>
    );
}
