import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Front from "./pages/Front";
import SessionPage from "./pages/Session";
import { PrivacyPage } from "./pages/Privacy";
import { TermsPage } from "./pages/Terms";
import { Error } from "./components/Error";

import "./styles/style.css";
import { HowToPage } from "./pages/HowTo";

const App = () => {
    return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Front />} />
            <Route path="/session/:id" element={<SessionPage />} />
            <Route path="/privacy/" element={<PrivacyPage />} />
            <Route path="/terms/" element={<TermsPage />} />
            <Route path="/howto/" element={<HowToPage />} />
            <Route path='*' element={<Error msg={404} />} />
          </Routes>
      </BrowserRouter>
    )
  }

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(<App />);