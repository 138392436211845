import { useState, useEffect } from "react";
import { notifySession } from "../api";
import Cookies from "universal-cookie";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { validateEmail } from "../utils/utils";

interface NotifyFormProps {
    id: string; 
}

export const NotifyForm = ({id}: NotifyFormProps) => {
    const [email, setEmail] = useState<string>("");
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

    useEffect(() => {
        let cookieEmail = new Cookies().get(`notify-session:${id}`);
        if (cookieEmail !== undefined && cookieEmail.length !== "") {
            setEmail(cookieEmail);
        }   
    }, []);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        var e = new String(email).toString().trim();
        if (e !== "" && validateEmail(e) === null) {
            setIsValidEmail(false);
            return;
        }
        setIsValidEmail(true);
        notifySession(id, e.toString())
            .then((resp) => {
                const cookies = new Cookies();
                cookies.set(`notify-session:${id}`, email, { path: "/", maxAge: 60 * 60 });
                setIsPopoverOpen(true);
                setTimeout(function(){
                    setIsPopoverOpen(false);
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <form className="notify" onSubmit={handleSubmit}>
            <div className="h5 mb-3"><span>Введите Email</span></div> 
            <small className="text-muted text-sm">Он нужен для получения уведомления поступления оплаты</small>
            <div className="mt-2 input-group">
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" placeholder="email для уведомления" />
                <div className="input-group-append">
                    <Popover 
                    onClickOutside={() => setIsPopoverOpen(false)}  
                    isOpen={isPopoverOpen} positions={['top', 'bottom', 'left', 'right']} content={({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'black'}
                        arrowSize={3}
                        arrowStyle={{ opacity: 1 }}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                        >
                        <div className="popover-content" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>email сохранен</div>
                        </ArrowContainer>
                    )}
                    >
                        <button className="btn btn-outline-secondary" onClick={handleSubmit} type="button">сохранить</button>
                    </Popover>
                </div>
            </div>
            <div className={isValidEmail ? "d-none" : "invalid-feedback d-block"}>
                введите существующий адрес эл. почты
            </div>
        </form>
    );
}