import React, { useLayoutEffect } from "react";
import { makeDocumentTitle } from "../utils/utils";
import Footer from "../components/Footer";

export const PrivacyPage = () => {
    const { REACT_APP_HOST } = process.env;
    const email = "help@" + REACT_APP_HOST;
    const mailto = "mailto:" + email;

    useLayoutEffect(() => {
        document.title = makeDocumentTitle("Политика конфиденциальности");
    }, []);

    return (
        <div className="container">
            <div className="space cosmo">
            <h1 className="mb-3">Политика конфиденциальности</h1>
            <p>Мы, {REACT_APP_HOST}, ценим вашу конфиденциальность и стремимся обеспечить безопасность ваших персональных данных. Настоящая политика конфиденциальности описывает, как мы собираем, используем и защищаем вашу личную информацию при использовании нашего сайта и предоставлении нам каких-либо персональных данных.</p>
            <p><strong>Собираемая информация</strong>. При использовании нашего сайта мы можем собирать следующие категории информации:</p>
            <ul>
                <li>Персональная информация, предоставленная вами при оформлении заказа или регистрации на нашем сайте, такая как имя, адрес электронной почты и платежные реквизиты.</li>
                <li>Информация о вашем взаимодействии с нашим сайтом, включая данные об использовании и просмотре страниц, информацию об устройствах, с которых вы заходите на сайт, и т. д.</li>
                <li>Информация об использовании платежных систем и криптовалютных кошельков для совершения покупок через наш сайт.</li>
            </ul>
            <p><strong>Использование информации</strong>. Мы используем собранную информацию для следующих целей:</p>
            <ul>
                <li>Обеспечения доступа к нашим услугам и выполнения ваших заказов.</li>
                <li>Улучшения качества нашего сайта и предоставляемых услуг.</li>
                <li>Обработки платежей и обеспечения безопасности транзакций.</li>
                <li>Отправки вам информации о наших продуктах, акциях и новостях, если вы дали на это согласие.</li>
            </ul>
            <p><strong>Защита информации</strong>. Мы прилагаем все необходимые меры безопасности для защиты вашей личной информации от несанкционированного доступа, использования или раскрытия. Мы не передаем вашу личную информацию третьим лицам без вашего согласия, за исключением случаев, предусмотренных законодательством.</p>
            <p><strong>Предоставление информации третьим лицам</strong>. Мы можем предоставлять вашу личную информацию третьим лицам только в случаях, предусмотренных законодательством, или если это необходимо для обработки платежей или выполнения заказов.</p>
            <p><strong>Контактная информация</strong>. Если у вас возникли вопросы или предложения относительно нашей политики конфиденциальности, пожалуйста, свяжитесь с нами по следующим контактным данным: <a href={mailto}>{email}</a>.</p>
            </div>
            <Footer showHowTo={false} />
        </div>
    );
}
