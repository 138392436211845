import axios from "axios";

axios.defaults.withCredentials = true

interface apiRequestParams {
    [propName: string]: any;
}

export async function apiRequest(method: string, type: string = "get", params: apiRequestParams): Promise<any>  {
    var url = process.env.REACT_APP_BACKEND_HOST + "/" + method;
    var req;
    switch (type) {
        case "get":
            req = axios.get(url, { params: params });
            break;
        case "post":
            req = axios.post(url, params);
            break;
    }
    return req;
}

export async function getSession(id: string): Promise<any> {
    return apiRequest("v1/session/", "post", {"sessionId": id});
}

export async function pickSession(id: string, coin: string): Promise<any> {
    return apiRequest("v1/session/pick/", "post", {"sessionId": id, "coin": coin});
}

export async function confirmSession(confirmation: string): Promise<any> {
    return apiRequest("v1/session/confirm/", "post", {"confirmation": confirmation});
}

export async function notifySession(id: string, email: string): Promise<any> {
    return apiRequest("v1/session/notify/", "post", {"sessionId": id, "email": email});
}